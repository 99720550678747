<template lang="pug">
    section#conteudo
        .wrapper(v-html="parseContent(contents.content.pt)")
</template>

<script>
import { props, parseContent } from '@/mixins/components'

export default {
    name: "section-conteudo",
    props,
    methods: {
        parseContent,
    },
}
</script>

<style lang="stylus" scoped src="@stylus/defaultTextos.styl"></style>
<style lang="stylus" scoped src="./Conteudo.styl"></style>
